<template>
  <v-card>
    <v-card-title class="text-h6">
      Chọn cấu hình
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table class="table-padding-2-no-top">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th>Tên</th>
              <th>Tạo</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="items.length > 0">
            <tr v-for="item in items" :key="`sc_${item.id}`">
              <td>{{ item.name }}</td>
              <td>{{ formatDateTime(item.created_at) }}</td>
              <td class="text-right">
                <v-btn x-small color="warning" @click="selectItem(item)"
                  >Chọn</v-btn
                >
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4" class="text-center font-italic">
                Chưa có cấu hình
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "SelectConfigTransferByRate",
  data: () => ({
    items: [],
  }),
  mounted() {
    this.getConfig();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    selectItem(item) {
      this.$emit("onSelect", item);
    },
    getConfig() {
      httpClient
        .post("/pos-get-goods-transfer-rate-config")
        .then(({ data }) => {
          this.items = [...data];
        });
    },
  },
};
</script>

<style scoped></style>
